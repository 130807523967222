import React from "react";
import BlogContainer from "../components/Blog/BlogContainer";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import BlogDetail from "../components/Blog/BlogDetail";

const BlogDetailPage: React.FC = () => {
  return (
    <>
      <Navbar />
      <BlogDetail />
    </>
  );
};

export default BlogDetailPage;
