import React, { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Fade,
  Grid,
  IconButton,
  Link,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../assets/css/Trademark/CardPriceTrademark.css";
import { Close } from "@mui/icons-material";
import "../../assets/css/Generics/FaqGlobal.css";

interface CardPriceTrademarkProps {}

const CardPriceTrademark: React.FC<CardPriceTrademarkProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  // modal
  const [openModalOne, setOpenModalOne] = useState(false);
  const [openModalTwo, setOpenModalTwo] = useState(false);

  const handleOpenOneModal = () => {
    setOpenModalOne(true);
  };

  const handleCloseOneModal = () => {
    setOpenModalOne(false);
  };

  const handleOpenTwoModal = () => {
    setOpenModalTwo(true);
  };

  const handleCloseTwoModal = () => {
    setOpenModalTwo(false);
  };

  return (
    <>
      <Grid container className="container-Price-trademark bg-orange">
        <Grid
          item
          xs={10}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            margin: "auto",
          }}
        >
          <Typography
            variant="h2"
            className="color-light title-Price-trademark"
          >
            Dove vuoi registrare il tuo marchio?
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          className="container-card-Price-trademark"
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Grid item xs={10} md={4}>
            <div className="Card-trademark bg-light">
              <Typography
                variant="h5"
                className="title-card-trademark color-orange"
              >
                Registra in Italia
              </Typography>

              <Typography className="text-price-trademark color-orange">
                €229
              </Typography>
              <Typography
                className="text-info-price-trademark-iva color-orange"
                style={{ marginTop: "-5px" }}
              >
                +IVA e altre imposte{" "}
              </Typography>
              <Typography
                onClick={handleOpenOneModal}
                className="text-info-price-trademark color-orange"
                style={{
                  marginTop: "10px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Quali sono le imposte dovute?
              </Typography>

              {/* lista  */}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  alignItems: "center",
                  marginTop: "40px",
                  textAlign: "start",
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {" "}
                  <div className="icon-list-card"></div>
                </Grid>
                <Grid item xs={10}>
                  <Typography className="text-card-trademark color-orange">
                    Gestione online della pratica
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  alignItems: "center",
                  marginTop: "20px",
                  textAlign: "start",
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {" "}
                  <div className="icon-list-card"></div>
                </Grid>
                <Grid item xs={10}>
                  <Typography className="text-card-trademark color-orange">
                    Assistenza completa di un professionista
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  alignItems: "center",
                  marginTop: "20px",
                  textAlign: "start",
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {" "}
                  <div className="icon-list-card"></div>
                </Grid>
                <Grid item xs={10}>
                  <Typography className="text-card-trademark color-orange">
                    Consegna di certificato di registrazione
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  alignItems: "center",
                  marginTop: "20px",
                  textAlign: "start",
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {" "}
                  <div className="icon-list-card"></div>
                </Grid>
                <Grid item xs={10}>
                  <Typography className="text-card-trademark color-orange">
                    Protezione per 10 anni
                  </Typography>
                </Grid>
              </Grid>
            </div>
            {/* <div className="container-button-Price-trademark">
              <a
                className="nav-link button-cta-Price-trademark"
                href="https://app.costituzionesrl.com/sign-up?redirect=trademark"
              >
                <Typography
                  className="color-light text-20"
                  style={{ fontWeight: 600 }}
                >
                  Richiedi subito
                </Typography>
              </a>
            </div> */}
          </Grid>

          <Grid item xs={10} md={4}>
            <div className="Card-trademark bg-light">
              <Typography
                variant="h5"
                className="title-card-trademark color-orange"
              >
                Registra in Europa
              </Typography>
              <Typography className="text-price-trademark color-orange">
                €349
              </Typography>
              <Typography
                className="text-info-price-trademark color-orange"
                style={{ marginTop: "-5px" }}
              >
                +IVA e altre imposte{" "}
              </Typography>
              <Typography
                onClick={handleOpenTwoModal}
                className="text-info-price-trademark color-orange"
                style={{
                  marginTop: "10px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Quali sono le imposte dovute?
              </Typography>

              {/* lista  */}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  alignItems: "center",
                  marginTop: "40px",
                  textAlign: "start",
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {" "}
                  <div className="icon-list-card"></div>
                </Grid>
                <Grid item xs={10}>
                  <Typography className="text-card-trademark color-orange">
                    Gestione online della pratica
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  alignItems: "center",
                  marginTop: "20px",
                  textAlign: "start",
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {" "}
                  <div className="icon-list-card"></div>
                </Grid>
                <Grid item xs={10}>
                  <Typography className="text-card-trademark color-orange">
                    Assistenza completa di un professionista
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  alignItems: "center",
                  marginTop: "20px",
                  textAlign: "start",
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {" "}
                  <div className="icon-list-card"></div>
                </Grid>
                <Grid item xs={10}>
                  <Typography className="text-card-trademark color-orange">
                    Consegna di certificato di registrazione
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  alignItems: "center",
                  marginTop: "20px",
                  textAlign: "start",
                }}
              >
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {" "}
                  <div className="icon-list-card"></div>
                </Grid>
                <Grid item xs={10}>
                  <Typography className="text-card-trademark color-orange">
                    Protezione per 10 anni
                  </Typography>
                </Grid>
              </Grid>
            </div>
            {/* <div className="container-button-Price-trademark">
              <a
                className="nav-link button-cta-Price-trademark"
                href="https://app.costituzionesrl.com/sign-up?redirect=trademark"
              >
                <Typography
                  className="color-light text-20"
                  style={{ fontWeight: 600 }}
                >
                  Richiedi subito
                </Typography>
              </a>
            </div> */}
          </Grid>

          <Grid item xs={6}>
            <div className="container-button-Price-trademark">
              <a
                className="nav-link button-cta-Price-trademark"
                href="https://app.legalfarm.it/sign-up?redirect=trademark"
              >
                <Typography
                  className="color-light text-20"
                  style={{ fontWeight: 600 }}
                >
                  Richiedi subito
                </Typography>
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {/* modal marchio italiano  */}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openModalOne}
        onClose={handleCloseOneModal}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={openModalOne}>
          <Grid container sx={{ width: "80%" }}>
            <Grid
              item
              xs={12}
              className="modal-container-faq-trademark"
              sx={{
                bgcolor: "background.paper",
                border: "none",
                boxShadow: 24,
                borderRadius: "5px",
                maxHeight: "80vh",
                overflowY: "auto",
                padding: "20px 30px 30px 30px",
              }}
            >
              <Grid
                container
                style={{ marginTop: "20px", marginBottom: "-20px" }}
              >
                <Grid item xs={8}></Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    className="close-modal-faq"
                    onClick={handleCloseOneModal}
                  >
                    <Close
                      className="color-orange"
                      style={{ marginRight: "7px", marginTop: "-8px" }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography
                className="color-orange text-20"
                style={{
                  fontWeight: "bold",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                Quali sono le imposte per la registrazione di un marchio
                italiano?
              </Typography>
              <Typography
                className="color-orange text-accoordion-faq"
                style={{ marginBottom: "10px" }}
              >
                Per la registrazione del marchio italiano le imposte ammontano a
                €199 se il marchio viene registrato per una singola classe
                merceologica. Per le eventuali classi successive alla prima sono
                dovuti ulteriori €34 per ogni classe aggiuntiva. Non
                preoccuparti, il nostro sistema calcola in automatico i costi
                per il tuo marchio in base ai dati che inserirai.
              </Typography>
            </Grid>
          </Grid>
        </Fade>
      </Modal>

      {/* modal marchio europeo  */}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openModalTwo}
        onClose={handleCloseTwoModal}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={openModalTwo}>
          <Grid container sx={{ width: "80%" }}>
            <Grid
              item
              xs={12}
              className="modal-container-faq-trademark"
              sx={{
                bgcolor: "background.paper",
                border: "none",
                boxShadow: 24,
                borderRadius: "5px",
                maxHeight: "80vh",
                overflowY: "auto",
                padding: "20px 30px 30px 30px",
              }}
            >
              <Grid
                container
                style={{ marginTop: "20px", marginBottom: "-20px" }}
              >
                <Grid item xs={8}></Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    className="close-modal-faq"
                    onClick={handleCloseTwoModal}
                  >
                    <Close
                      className="color-orange"
                      style={{ marginRight: "7px", marginTop: "-8px" }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography
                className="color-orange text-20"
                style={{
                  fontWeight: "bold",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                Quali sono le imposte per la registrazione di un marchio
                europeo?
              </Typography>
              <Typography
                className="color-orange text-accoordion-faq"
                style={{ marginBottom: "10px" }}
              >
                Per la registrazione del marchio a livello comunitario le
                imposte ammontano a €850 se il marchio viene registrato per una
                singola classe merceologica. Per la seconda eventuale classe
                sono dovuti ulteriori €50. Per le eventuali classi successive
                alla seconda sono dovuti ulteriori €150 per ogni classe
                aggiuntiva. Non preoccuparti, il nostro sistema calcola in
                automatico i costi per il tuo marchio in base ai dati che
                inserirai.
              </Typography>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

export default CardPriceTrademark;
