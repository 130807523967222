import React, { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Fade,
  Grid,
  IconButton,
  Link,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../assets/css/Company/CardPriceCompany.css";
import { Close } from "@mui/icons-material";

interface CardPriceCompanyProps {}

const CardPriceCompany: React.FC<CardPriceCompanyProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  // modal

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid container className="container-Price-company bg-orange">
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Typography variant="h2" className="color-light title-Price-company">
            Con noi, tutti i costi saranno ridotti
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          className="container-card-Price-company"
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Grid item xs={10} md={4} className="Card-company bg-light">
            <Typography
              variant="h5"
              className="title-card-company color-orange"
            >
              Apri online la tua Srl
            </Typography>
            <Typography className="text-price-company color-orange">
              €199
            </Typography>
            <Typography
              className="text-info-price-company color-orange"
              style={{ marginTop: "-5px" }}
            >
              +IVA e costi notarili{" "}
            </Typography>

            <Typography
              onClick={handleOpenModal}
              className="text-info-costs-notary color-orange"
              style={{
                marginTop: "10px",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Quali sono i costi notarili?
            </Typography>

            {/* lista  */}
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                alignItems: "center",
                marginTop: "40px",
                textAlign: "start",
              }}
            >
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <div className="icon-list-card"></div>
              </Grid>
              <Grid item xs={10}>
                <Typography className="text-card-company color-orange">
                  Scegli tra Semplificata, Ordinaria e Innovativa
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                alignItems: "center",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <div className="icon-list-card"></div>
              </Grid>
              <Grid item xs={10}>
                <Typography className="text-card-company color-orange">
                  Crea lo statuto e l’atto costitutivo
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                alignItems: "center",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <div className="icon-list-card"></div>
              </Grid>
              <Grid item xs={10}>
                <Typography className="text-card-company color-orange">
                  Ricevi la partita IVA gratis
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                alignItems: "center",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <div className="icon-list-card"></div>
              </Grid>
              <Grid item xs={10}>
                <Typography className="text-card-company color-orange">
                  Affidati alla nostra procedura guidata
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                alignItems: "center",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <div className="icon-list-card"></div>
              </Grid>
              <Grid item xs={10}>
                <Typography className="text-card-company color-orange">
                  Ricevi l’assistenza di un professionista
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                alignItems: "center",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <div className="icon-list-card"></div>
              </Grid>
              <Grid item xs={10}>
                <Typography className="text-card-company color-orange">
                  Costituisci in videocall con il notaio
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={10} md={5} className="box-text-card-Price-company">
            <Typography className="color-light text-20">
              Grazie al nostro portale potrai{" "}
              <span style={{ fontWeight: "bold" }}>
                costituire la tua società online
              </span>{" "}
              facilmente, comodamente da casa, con il supporto di professionisti
              esperti e competenti.
            </Typography>
            <br />
            <Typography className="color-light text-20">
              Nella nostra formula{" "}
              <span style={{ fontWeight: "bold" }}>
                è incluso tutto ciò che serve
              </span>
              , dai documenti costitutivi fino all’iscrizione nel Registro delle
              Imprese. Inoltre garantiamo{" "}
              <span style={{ fontWeight: "bold" }}>costi notarili ridotti</span>{" "}
              poiché il nostro statuto è basato sul modello ministeriale, con la
              possibilità di personalizzare alcune regole tramite il nostro
              modulo guidato.
            </Typography>

            <div className="container-button-Price-company">
              <a
                className="nav-link button-cta-Price-company"
                href="https://app.legalfarm.it/sign-up?redirect=company"
              >
                <Typography
                  className="color-light text-20"
                  style={{ fontWeight: 600 }}
                >
                  Inizia ora!
                </Typography>
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {/* modal  */}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={openModal}>
          <Grid container sx={{ width: "80%" }}>
            <Grid
              item
              xs={12}
              sx={{
                position: "absolute",
                width: "80%",
                top: 100,
                left: "50%",
                transform: "translate(-50%, 0%)",
                bgcolor: "background.paper",
                border: "none",
                boxShadow: 24,
                borderRadius: "5px",
                maxHeight: "80vh",
                overflowY: "auto",
                padding: "20px 30px 25px 30px",
              }}
            >
              <Grid
                container
                style={{ marginTop: "20px", marginBottom: "-20px" }}
              >
                <Grid item xs={8}></Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    className="close-modal-faq"
                    onClick={handleCloseModal}
                  >
                    <Close
                      className="color-orange"
                      style={{ marginRight: "7px", marginTop: "-8px" }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography
                className="color-orange text-20"
                style={{
                  fontWeight: "bold",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                Quali sono i costi da versare al notaio?
              </Typography>
              <Typography
                className="color-orange text-accoordion-faq"
                style={{ marginBottom: "10px" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  A) In caso di Srl semplificata (Srls): €930
                </span>
                <br />
                Dettaglio:
              </Typography>

              <Typography className="color-orange">
                <ul className="color-orange">
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Imposta di registro €200
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Diritti camerali €120
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Iscrizione a repertorio €2
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Onorario notaio €426,50
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Contributi Cassa Notariato €71,50
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      IVA su onorario e contributi €110
                    </Typography>
                  </li>
                </ul>
              </Typography>
              <Typography
                className="color-orange text-accoordion-faq"
                style={{ marginBottom: "20px" }}
              >
                Se la società ha un solo socio sono previsti ulteriori €90 di
                diritti camerali di segreteria.
              </Typography>

              <br />

              <Typography
                style={{ marginBottom: "20px", fontWeight: "bold" }}
                className="color-orange text-accoordion-faq"
              >
                B) In caso di Srl tradizionale: €1.203,50 <br />
                Dettaglio:
              </Typography>
              <Typography className="color-orange">
                <ul className="color-orange">
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Imposta di registro €200
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Imposta di bollo €156
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Diritti camerali €120
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Diritti di segreteria €90
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Tassa archivio €27,50
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Iscrizione a repertorio €2
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Onorario notaio €426,50
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Contributi Cassa Notariato €71,50
                    </Typography>
                  </li>{" "}
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      IVA su onorari e contributi €110
                    </Typography>
                  </li>
                </ul>
              </Typography>
              <Typography
                className="color-orange text-accoordion-faq"
                style={{ marginBottom: "20px" }}
              >
                Se la società ha un solo socio sono previsti ulteriori €90 di
                diritti camerali di segreteria. <br />I costi notarili possono
                aumentare leggermente in caso di capitale sociale superiore a
                €37.000.
              </Typography>

              <br />

              <Typography
                style={{ marginBottom: "20px", fontWeight: "bold" }}
                className="color-orange text-accoordion-faq"
              >
                C) In caso di Startup Innovativa: €837,50
              </Typography>
              <Typography className="color-orange">
                <ul className="color-orange">
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Imposta di registro €200
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      Tassa archivio €27,50
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Iscrizione a repertorio €2
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Onorario notaio €426,50
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Contributi Cassa Notariato €71,50
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      IVA su onorari e contributi €110
                    </Typography>
                  </li>
                </ul>
              </Typography>
              <Typography
                className="color-orange text-accoordion-faq"
                style={{ marginBottom: "20px" }}
              >
                Se la società ha un solo socio sono previsti ulteriori €90 di
                diritti camerali di segreteria.
                <br />I costi notarili possono aumentare leggermente in caso di
                capitale sociale superiore a €37.000.
              </Typography>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

export default CardPriceCompany;
