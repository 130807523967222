import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/AboutUs/SectionLexia.css";

interface SectionLexiaProps {}

const SectionLexia: React.FC<SectionLexiaProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="bg-light container-aboutUs-sectionLexia">
      <Grid
        item
        xs={10}
        md={8}
        style={{
          textAlign: "center",
        }}
      >
        <Typography
          component="h1"
          className="color-primary title-aboutUs-sectionLexia"
        >
          Siamo parte di Lexia Avvocati
        </Typography>
        <Typography className="description-aboutUs-sectionLexia text-20 color-primary">
          Lexia Avvocati è un primario studio legale italiano con sedi a Milano,
          Roma e Palermo. Più di 70 professionisti, tra avvocati, commercialisti
          e consulenti, che assistono ogni giorno società, gruppi nazionali e
          multinazionali, banche, imprese, PMI, startup e persone.
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <div className="Logo-Lexia-AboutUs"></div>
      </Grid>
      <Grid
        item
        xs={10}
        md={8}
        style={{
          textAlign: "center",
        }}
      >
        <Typography
          component="h1"
          className="color-primary title-aboutUs-sectionLexia"
        >
          La nostra missione
        </Typography>
        <Typography className="description-aboutUs-sectionLexia text-20 color-primary">
          Lavoriamo ogni giorno{" "}
          <span style={{ fontWeight: "bold" }}>
            per rendere accessibili a tutti i servizi legali e automatizzarli
            con il supporto della tecnologia
          </span>{" "}
          , per far risparmiare tempo, denaro e ridurre lo stress.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SectionLexia;
