import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Company/AdvantagesCompany.css";

interface AdvantagesCompanyProps {}

const AdvantagesCompany: React.FC<AdvantagesCompanyProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      className="container-AdvantagesCompany"
      style={{ display: "flex", flexDirection: md ? "row" : "column-reverse" }}
    >
      <Grid item className="container-AdvantagesCompany-content" xs={12} md={6}>
        <Grid
          item
          xs={9}
          sm={10}
          md={12}
          className="container-AdvantagesCompany-text"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h2"
            className="title-AdvantagesCompany color-primary"
          >
            Che vantaggi avrai
          </Typography>

          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={1}>
              <div className="icon-vat"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="color-primary description-AdvantagesCompany">
                <span style={{ fontWeight: "bold" }} className="color-primary">
                  Partiva IVA della società inclusa
                </span>{" "}
                , senza costi aggiuntivi
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginTop: "50px" }}
          >
            <Grid item xs={1}>
              <div className="icon-team"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="description-AdvantagesCompany color-primary">
                Supporto e{" "}
                <span className="color-primary" style={{ fontWeight: "bold" }}>
                  guida su tutti gli adempimenti
                </span>{" "}
                di inizio attività
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginTop: "50px" }}
          >
            <Grid item xs={1}>
              <div className="icon-easy"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="description-AdvantagesCompany color-primary">
                Pratica seguita da un{" "}
                <span style={{ fontWeight: "bold" }}>
                  professionista sempre a tua disposizione
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4.5} style={{ height: "100%" }}>
        <Grid item xs={12} className="container-img-AdvantagesCompany">
          <div className="img-AdvantagesCompany"></div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdvantagesCompany;
