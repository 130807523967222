// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enLanguange from "./en.json";
import itLanguange from "./it.json";

// Impostazioni di base di i18next
i18n
  .use(LanguageDetector) // Rileva la lingua dal browser
  .use(initReactI18next) // Connette i18n a React

  .init({
    fallbackLng: "it", // Lingua di fallback nel caso in cui la lingua rilevata non sia disponibile
    debug: true,

    // Configurazione delle chiavi e dei file di traduzione
    // resources: {
    //   en: {
    //     translation: {
    //       homeTitle: "Welcome to our website!",
    //       Servizi: "Online Services",
    //     },
    //   },
    //   it: {
    //     translation: {
    //       homeTitle: "Benvenuti nel sito!",
    //       Servizi: "Servizi",
    //     },
    //   },
    // },

    resources: {
      en: { translation: enLanguange },
      it: { translation: itLanguange },
    },

    detection: {
      order: ["localStorage", "navigator"],
    },
  });

export default i18n;
