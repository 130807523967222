import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/DigitalSignature/HowActiveDigitalSignature.css";

interface HowActiveDigitalSignatureProps {}

const HowActiveDigitalSignature: React.FC<
  HowActiveDigitalSignatureProps
> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      className="bg-purple container-card-services-digitalSignature"
    >
      <Grid
        item
        xs={8}
        sm={10}
        md={10}
        lg={6}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          className="title-card-services-digitalSignature color-light"
        >
          Come attivare la tua firma
        </Typography>
      </Grid>

      <Grid
        md={10}
        xs={12}
        item
        container
        className="box-card-service-digitalSignature"
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={10} className="bubbles-container">
          <Grid
            item
            xs={12}
            className="container-interno-HowDoesThatShareTransfer"
          >
            <div
              className="bubble bubble-text first-bubble-signature color-purple"
              style={{ width: "80px" }}
            >
              1
            </div>

            <div className="box-text">
              <p className="description-bubble p-0 m-0 color-light">
                Scegli la tipologia di firma più adatta alle tue esigenze*,
                carica i tuoi dati anagrafici e il documento d’identità nella
                nostra dashboard
              </p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="container-interno-HowDoesThatShareTransfer"
          >
            <div
              className="bubble bubble-text second-bubble-signature color-purple"
              style={{ width: "80px" }}
            >
              2
            </div>
            <div className="box-text">
              <p className="description-bubble p-0 m-0 color-light">
                Esegui la procedura di identificazione online in videochiamata
                con un operatore certificato
              </p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="container-interno-HowActiveDigitalSignature"
          >
            <div
              className="bubble bubble-text three-bubble-signature color-purple"
              style={{ width: "80px" }}
            >
              3
            </div>
            <div className="box-text">
              <p className="description-bubble p-0 m-0 color-light">
                Avrai la tua firma attiva entro poche ore, con autenticità,
                integrità e validità legale garantita
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HowActiveDigitalSignature;
