import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Logo from "../../assets/img/costituzioneSrl_logo.png";
import "../../assets/css/Navbar/navbar.css";
import "../../assets/css/Global.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faHome,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "../../utils/Translate/fileTranslate/i18n.js";
import { Grid, Typography } from "@mui/material";
import NavbarDesktop from "./NavbarDesktop";
import NavbarSmart from "./NavbarSmart";

const lngs: {
  en: { icon: React.ReactNode };
  it: { icon: React.ReactNode };
} = {
  en: { icon: "it" },
  it: { icon: "en" },
};

const Navbar: React.FC = () => {
  // codice traduzione
  const { t, i18n } = useTranslation();

  return (
    <>
      <NavbarSmart />
      <NavbarDesktop />
    </>
  );
};

export default Navbar;
