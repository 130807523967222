import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Logo from "../../assets/img/Logo/logo-costituzione.png";
import "../../assets/css/Navbar/navbar.css";
import "../../assets/css/Global.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleUp,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "../../utils/Translate/fileTranslate/i18n.js";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const lngs: {
  en: { icon: React.ReactNode };
  it: { icon: React.ReactNode };
} = {
  en: { icon: "it" },
  it: { icon: "en" },
};

const NavbarDesktop: React.FC = () => {
  const location = useLocation();
  // codice traduzione
  const { t, i18n } = useTranslation();
  library.add(faAngleDown);
  library.add(faAngleUp);
  library.add(faHouse);

  // Gestione menu soluzioni
  // navbar pc
  const [soluzioniVisible, setSoluzioniVisible] = useState(false);
  const [risorseVisible, setRisorseVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const soluzioniRef = useRef<HTMLLIElement>(null);
  const risorseRef = useRef<HTMLLIElement>(null);

  const handleMenuClick = (id: number) => {
    if (id === 1) {
      setSoluzioniVisible(!soluzioniVisible);
      if (risorseVisible) {
        setRisorseVisible(false);
      }
    }

    if (id === 2) {
      setRisorseVisible(!risorseVisible);
      if (soluzioniVisible) {
        setSoluzioniVisible(false);
      }
    }
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        (soluzioniRef.current &&
          !soluzioniRef.current.contains(event.target) &&
          soluzioniVisible &&
          !event.target.closest(".soluzioni-dropdown")) ||
        (risorseRef.current &&
          !risorseRef.current.contains(event.target) &&
          risorseVisible &&
          !event.target.closest(".soluzioni-dropdown"))
      ) {
        setSoluzioniVisible(false);
        setRisorseVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [soluzioniVisible, risorseVisible]);

  useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.pageYOffset);
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {/* pc   */}
      <nav
        className="navbar navbar-expand-lg navbar-pc pt-3 fixed-top bg-light"
        style={{ position: "sticky", top: 0 }}
      >
        <div className="container-fluid mx-2 my-2">
          <a
            href={"/"}
            style={{ textDecoration: "none", marginLeft: "20px" }}
            className="color-primary"
          >
            <div className="logo"></div>
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i className="bi bi-list icon-hamburger bg-primary"></i>
              <i className="bi bi-list icon-hamburger bg-primary"></i>
              <i className="bi bi-list icon-hamburger bg-primary"></i>
            </span>
          </button>

          <div
            className="collapse navbar-collapse col-6 col-md-7"
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto me-3">
              {/* HOME */}
              <li
                className="nav-item container-link"
                style={{ marginRight: "10px" }}
              >
                <a
                  className="nav-link"
                  href="/"
                  style={{ marginTop: "-1px", marginRight: "-10px" }}
                >
                  {/* <FontAwesomeIcon
                    className={`${
                      location.pathname === "/"
                        ? "color-yellow"
                        : "color-primary"
                    }`}
                    icon={faHouse}
                  /> */}
                  <div
                    className={`${
                      location.pathname === "/"
                        ? "icon-home-active"
                        : "icon-home"
                    }`}
                  ></div>
                </a>
              </li>

              {/* SOLUZIONI  */}
              <li
                className="nav-item container-link margin-link"
                ref={soluzioniRef}
              >
                <a
                  className="nav-link d-flex"
                  onClick={() => handleMenuClick(1)}
                >
                  <Typography
                    className={`${
                      location.pathname === "/registrazione-marchio-online" ||
                      location.pathname === "/cessione-quote-srl-online" ||
                      location.pathname === "/apertura-srl-online" ||
                      location.pathname === "/firma-digitale"
                        ? "link color-yellow"
                        : "link color-primary"
                    }`}
                  >
                    Soluzioni
                  </Typography>
                  {soluzioniVisible ? (
                    <FontAwesomeIcon
                      className={`icon-dropdown ${
                        location.pathname === "/registrazione-marchio-online" ||
                        location.pathname === "/cessione-quote-srl-online" ||
                        location.pathname === "/apertura-srl-online" ||
                        location.pathname === "/firma-digitale"
                          ? "color-yellow"
                          : "color-primary"
                      }`}
                      icon={faAngleUp}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={`icon-dropdown ${
                        location.pathname === "/registrazione-marchio-online" ||
                        location.pathname === "/cessione-quote-srl-online" ||
                        location.pathname === "/apertura-srl-online" ||
                        location.pathname === "/firma-digitale"
                          ? "color-yellow"
                          : "color-primary"
                      }`}
                      icon={faAngleDown}
                    />
                  )}
                </a>
              </li>

              {/* PREZZI  */}
              {/* <li className="nav-item container-link margin-link ">
                <a className="nav-link" href="/price">
                  <Typography
                    className={`link ${
                      location.pathname === "/price"
                        ? "color-yellow"
                        : "color-primary"
                    }`}
                  >
                    Prezzi
                  </Typography>
                </a>
              </li> */}

              {/* RISORSE  */}
              <li
                className="nav-item container-link margin-link "
                ref={risorseRef}
                style={{ marginRight: "10px" }}
              >
                <a
                  className="nav-link d-flex"
                  onClick={() => handleMenuClick(2)}
                >
                  <Typography
                    className={`link ${
                      location.pathname === "/faq" || location.pathname === "/b"
                        ? "color-yellow"
                        : "color-primary"
                    }`}
                  >
                    Risorse
                  </Typography>
                  {risorseVisible ? (
                    <FontAwesomeIcon
                      className={`icon-dropdown ${
                        location.pathname === "/faq" ||
                        location.pathname === "/b"
                          ? "color-yellow"
                          : "color-primary"
                      }`}
                      icon={faAngleUp}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={`icon-dropdown ${
                        location.pathname === "/faq" ||
                        location.pathname === "/b"
                          ? "color-yellow"
                          : "color-primary"
                      }`}
                      icon={faAngleDown}
                    />
                  )}
                </a>
              </li>

              {/* AZIENDA  */}
              <li className="nav-item container-link margin-link">
                <a className="nav-link" href="/azienda">
                  <Typography
                    className={`link ${
                      location.pathname === "/azienda"
                        ? "color-yellow"
                        : "color-primary"
                    }`}
                  >
                    Azienda
                  </Typography>
                </a>
              </li>

              {/* ACCEDI */}
              <li className="nav-item marginButton">
                <a
                  className="nav-link button-login button-bg-cta"
                  href="https://app.legalfarm.it/sign-up"
                >
                  <Typography className="text-button color-primary">
                    Accedi
                  </Typography>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* menu soluzioni */}
      <Grid
        container
        className="soluzioni-dropdown bg-purple"
        style={{
          display: "flex",
          justifyContent: "center",
          height: soluzioniVisible ? "55vh" : "0",
          position: "fixed",
          top: "58px",
          zIndex: 1000,
        }}
      >
        <Grid
          item
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: soluzioniVisible ? 1 : 0,
            transition: `opacity 0.1s ${soluzioniVisible ? "0.1s" : "0s"}`,
          }}
        >
          <Grid
            component="a"
            href="/apertura-srl-online"
            item
            xs={2.1}
            style={{ textDecoration: "none", marginTop: "10px" }}
            className="card-soluzioni bg-light link-soluzioni"
          >
            {/* <a className="link-soluzioni"> */}
            <Typography
              className="title-card-soluzioni color-primary"
              style={{ textAlign: "center" }}
            >
              Apertura Srl
            </Typography>
            {/* </a> */}
          </Grid>

          <Grid
            item
            component="a"
            xs={2.1}
            href="/cessione-quote-srl-online"
            className="card-soluzioni bg-light"
            style={{
              textDecoration: "none",
              marginLeft: "40px",
              marginTop: "10px",
            }}
          >
            {/* <a className="link-soluzioni"> */}
            <Typography
              className="title-card-soluzioni color-primary"
              style={{ textAlign: "center" }}
            >
              Cessione quote Srl
            </Typography>
            {/* </a> */}
          </Grid>

          <Grid
            item
            component="a"
            href="/registrazione-marchio-online"
            xs={2.1}
            className="card-soluzioni bg-light link-soluzioni"
            style={{
              textDecoration: "none",
              marginLeft: "40px",
              marginTop: "10px",
            }}
          >
            {/* <a> */}
            <Typography
              className="title-card-soluzioni color-primary"
              style={{ textAlign: "center" }}
            >
              Registrazione marchio
            </Typography>
            {/* </a> */}
          </Grid>

          <Grid
            item
            component="a"
            xs={2.1}
            href="/firma-digitale"
            className="card-soluzioni bg-light link-soluzioni"
            style={{
              textDecoration: "none",
              marginLeft: "40px",
              marginTop: "10px",
            }}
          >
            {/* <a> */}
            <Typography
              className="title-card-soluzioni color-primary"
              style={{ textAlign: "center" }}
            >
              Attivazione firma digitale
            </Typography>
            {/* </a> */}
          </Grid>
        </Grid>
      </Grid>

      {/* menu risorse  */}
      <Grid
        container
        className="soluzioni-dropdown bg-purple"
        style={{
          display: "flex",
          justifyContent: "center",
          height: risorseVisible ? "55vh" : "0",
          position: "fixed",
          top: "58px",
          zIndex: 1000,
        }}
      >
        <Grid
          item
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: risorseVisible ? 1 : 0,
          }}
        >
          <Grid
            component="a"
            href="/faq"
            item
            xs={2.1}
            style={{ textDecoration: "none", marginTop: "10px" }}
            className="card-risorse bg-light link-soluzioni"
          >
            <Typography
              className="title-card-soluzioni color-primary"
              style={{ textAlign: "center" }}
            >
              Faq
            </Typography>
          </Grid>

          <Grid
            item
            component="a"
            xs={2.1}
            href="/b"
            className="card-risorse bg-light link-soluzioni"
            style={{
              marginLeft: "40px",
              textDecoration: "none",
              marginTop: "10px",
            }}
          >
            <Typography
              className="title-card-soluzioni color-primary"
              style={{ textAlign: "center" }}
            >
              Blog
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NavbarDesktop;
