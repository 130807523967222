import React from "react";
import { Grid, Typography } from "@mui/material";
import "../../assets/css/Footer/Footer.css";
import Logo from "../../assets/img/costituzioneSrl_logo.png";
import { useTranslation } from "react-i18next";
import { LinkedIn } from "@mui/icons-material";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Grid container className="container-footer bg-purple-dark">
      <Grid
        item
        xs={11}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="container-logo-footer">
          <a
            href="/"
            style={{ textDecoration: "none", marginLeft: "-20px" }}
            className="color-light"
          >
            <div className="logo-footer"></div>
          </a>
        </div>

        <Grid
          container
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={12} sm={7}>
            <Grid item xs={12} className="container-link-page-servizi">
              <a href="/apertura-srl-online" className="color-light">
                Apertura Srl
              </a>{" "}
              <a
                href="/cessione-quote-srl-online"
                className="color-light link-footer"
              >
                Cessione quote Srl
              </a>{" "}
              <a
                href="/registrazione-marchio-online"
                className="color-light link-footer"
              >
                Registrazione marchio
              </a>{" "}
              <a href="/firma-digitale" className="color-light link-footer">
                Attivazione firma digitale
              </a>{" "}
            </Grid>
            <Grid item xs={12} className="container-link-page-servizi">
              <a href="/azienda" className="color-light">
                Azienda
              </a>{" "}
              <a href="/faq" className="color-light link-footer">
                Faq
              </a>{" "}
              <a href="/b" className="color-light link-footer">
                Blog
              </a>{" "}
            </Grid>
            <Grid item xs={12} lg={9} className="container-badge-payment">
              <Grid container alignItems="center">
                <Grid
                  item
                  xs={12}
                  sm={5}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="img-footer-badge"></div>
                  <Typography
                    className="color-light"
                    style={{
                      fontSize: "14px",
                      marginLeft: "20px",
                      marginTop: "4px",
                    }}
                  >
                    Pagamenti sicuri con:
                  </Typography>
                  {/* <div className="img-footer-badge"></div> */}
                </Grid>
                <Grid item xs={12} sm={5} className="container-logo-payment">
                  <div className="img-footer-visa"></div>
                  <div className="img-footer-mastercard"></div>
                  <div className="img-footer-amex"></div>
                  <div className="img-footer-paypal"></div>
                  <div className="img-footer-stripe"></div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography className="text-azienda color-light">
              LegalFarm è un servizio offerto da <br /> CostituzioneSrl società
              a responsabilità limitata <br />
              Sede legale: Via del Lauro 9, 20121 Milano <br />
              Partita IVA e Codice Fiscale: 10353830960
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={11}
        style={{
          border: "1px solid #fff",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      />
      <Grid
        item
        xs={11}
        style={{ display: "flex", marginTop: "10px", marginBottom: "20px" }}
      >
        <Grid item xs={4}>
          <Typography className="color-light" style={{ fontSize: "14px" }}>
            © 2022-2024 LegalFarm - CostituzioneSrl – Tutti i diritti riservati.
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
          <a href="https://www.linkedin.com/company/legalfarm/" target="_blank">
            <LinkedIn
              style={{ fontSize: "32px", marginTop: "-5px", cursor: "pointer" }}
              className="color-light"
            />
          </a>
        </Grid>
        <Grid
          item
          xs={4}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography className="color-light" style={{ fontSize: "14px" }}>
            <a href="/termini-e-condizioni" className="color-light">
              Termini e condizioni
            </a>{" "}
            -{" "}
            <a href="/informativa-privacy" className="color-light">
              Privacy
            </a>{" "}
            -{" "}
            <a href="/cookie" className="color-light">
              Cookie
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
