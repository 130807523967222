import React, { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import {
  Box,
  Fade,
  Grid,
  IconButton,
  Link,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "../../assets/css/ShareTransfer/CardPriceShareTransfer.css";
import "../../assets/css/Generics/FaqGlobal.css";

import { Close } from "@mui/icons-material";

interface CardPriceShareTransferProps {}

const CardPriceShareTransfer: React.FC<CardPriceShareTransferProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  // modal

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid container className="container-Price-shareTransfer bg-orange">
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Typography
            variant="h2"
            className="color-light title-Price-shareTransfer"
          >
            Costi e dettagli
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          className="container-card-Price-shareTransfer"
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Grid item xs={10} md={4} className="Card-shareTransfer bg-light">
            <Typography
              variant="h5"
              className="title-card-shareTransfer color-orange"
            >
              Cessione quote Srl
            </Typography>
            <Typography className="text-price-shareTransfer color-orange">
              €249
            </Typography>
            <Typography
              className="text-info-price-shareTransfer-iva color-orange"
              style={{ marginTop: "-5px" }}
            >
              +IVA e altre imposte{" "}
            </Typography>
            <Typography
              onClick={handleOpenModal}
              className="text-info-price-shareTransfer color-orange"
              style={{
                marginTop: "10px",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Quali sono le imposte e le possibili variazioni di prezzo?
            </Typography>

            {/* lista  */}
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                alignItems: "center",
                marginTop: "40px",
                textAlign: "start",
              }}
            >
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <div className="icon-list-card"></div>
              </Grid>
              <Grid item xs={10}>
                <Typography className="text-card-shareTransfer color-orange">
                  Valida per Srls, Srl Ordinaria o Startup
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                alignItems: "center",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <div className="icon-list-card"></div>
              </Grid>
              <Grid item xs={10}>
                <Typography className="text-card-shareTransfer color-orange">
                  Procedura guidata interamente online
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                alignItems: "center",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <div className="icon-list-card"></div>
              </Grid>
              <Grid item xs={10}>
                <Typography className="text-card-shareTransfer color-orange">
                  Assistenza di un team di professionisti
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                alignItems: "center",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              <Grid
                item
                xs={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {" "}
                <div className="icon-list-card"></div>
              </Grid>
              <Grid item xs={10}>
                <Typography className="text-card-shareTransfer color-orange">
                  Aggiornamento visura della società
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={10}
            md={5}
            className="box-text-card-Price-shareTransfer"
          >
            <Typography className="color-light text-20">
              Crea{" "}
              <span style={{ fontWeight: "bold" }}>in pochi minuti online</span>{" "}
              l’accordo di cessione delle quote della società, riducendo tempo e
              costi. Il nostro servizio è{" "}
              <span style={{ fontWeight: "bold" }}>
                senza abbonamento: paghi solo la singola pratica di cessione.
              </span>
            </Typography>
            <div className="container-button-Price-shareTransfer">
              <a
                className="nav-link button-cta-Price-shareTransfer"
                href="https://app.legalfarm.it/sign-up?redirect=shareTransfer"
              >
                <Typography
                  className="color-light text-20"
                  style={{ fontWeight: 600 }}
                >
                  Inizia subito
                </Typography>
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {/* modal  */}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={openModal}>
          <Grid container sx={{ width: "80%" }}>
            <Grid
              item
              xs={12}
              sx={{
                position: "absolute",
                width: "80%",
                top: 100,
                left: "50%",
                transform: "translate(-50%, 0%)",
                bgcolor: "background.paper",
                border: "none",
                boxShadow: 24,
                borderRadius: "5px",
                maxHeight: "80vh",
                overflowY: "auto",
                padding: "20px 30px 25px 30px",
              }}
            >
              <Grid
                container
                style={{ marginTop: "20px", marginBottom: "-20px" }}
              >
                <Grid item xs={8}></Grid>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    className="close-modal-faq"
                    onClick={handleCloseModal}
                  >
                    <Close
                      className="color-orange"
                      style={{ marginRight: "7px", marginTop: "-8px" }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              <Typography
                className="color-orange text-20"
                style={{
                  fontWeight: "bold",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              >
                Quali sono le imposte dovute e le possibili variazioni del
                prezzo?
              </Typography>
              <Typography
                className="color-orange text-accoordion-faq"
                style={{ marginBottom: "10px" }}
              >
                Le imposte dovute sono le seguenti: <br />
              </Typography>
              <Typography className="color-orange">
                <ul className="color-orange">
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Imposta di registro (Agenzia delle entrate) €200
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Imposta di bollo (Agenzia delle entrate) €15
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Diritti di segreteria (Camera di commercio) €90
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Imposta di bollo (Camera di commercio) €65
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      className="color-orange text-accoordion-faq"
                      style={{ marginBottom: "5px" }}
                    >
                      {" "}
                      Tariffa di deposito €2,44
                    </Typography>
                  </li>
                </ul>
              </Typography>
              <Typography
                className="color-orange text-accoordion-faq"
                style={{ marginBottom: "20px" }}
              >
                <span style={{ fontWeight: "bold" }}>Totale €372,44</span>{" "}
                <br />
                per 1 singola operazione di cessione
              </Typography>
              <Typography
                style={{ marginBottom: "20px", lineHeight: "1.8" }}
                className="color-orange text-accoordion-faq"
              >
                Nel caso di più operazioni di cessione,{" "}
                <span style={{ fontWeight: "bold" }}>
                  per ogni operazione aggiuntiva
                </span>{" "}
                sono previsti ulteriori{" "}
                <span style={{ fontWeight: "bold" }}>€200</span> di imposta di
                registro e <span style={{ fontWeight: "bold" }}>€150+IVA</span>{" "}
                sul prezzo base della pratica.{" "}
                <span style={{ fontWeight: "bold" }}>Esempio</span>: se l’unica
                operazione presente nell’atto di cessione riguarda il soggetto A
                che cede la propria quota al soggetto B allora non ci sarà
                nessun sovrapprezzo. Se invece ci sono più venditori o più
                acquirenti allora per ogni operazione di cessione aggiuntiva
                alla prima l’imposta di registro aumenterà di €200 e il prezzo
                base del nostro servizio aumenterà di €150+IVA. <br />
                Non preoccuparti! Il{" "}
                <span style={{ fontWeight: "bold" }}>
                  nostro sistema elabora in automatico il costo totale
                </span>{" "}
                in base al n. di operazioni che inserisci nell’atto.
              </Typography>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

export default CardPriceShareTransfer;
