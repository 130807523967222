import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/DigitalSignature/AdvantagesDigitalSignature.css";

interface AdvantagesDigitalSignatureProps {}

const AdvantagesDigitalSignature: React.FC<
  AdvantagesDigitalSignatureProps
> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      className="container-AdvantagesDigital"
      style={{ display: "flex", flexDirection: md ? "row" : "column-reverse" }}
    >
      <Grid item className="container-AdvantagesDigital-content" xs={12} md={6}>
        <Grid
          item
          xs={8.5}
          sm={10}
          md={12}
          className="container-AdvantagesDigital-text"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h2"
            className="title-AdvantagesDigital color-primary"
          >
            Che vantaggi avrai
          </Typography>

          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={1}>
              <div className="icon-easy-signature"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="color-primary description-AdvantagesDigital">
                <span style={{ fontWeight: "bold" }} className="color-primary">
                  istruzioni complete per l’attivazione
                </span>{" "}
                all’interno del nostro portale
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginTop: "50px" }}
          >
            <Grid item xs={1}>
              <div className="icon-team-digital"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="description-AdvantagesDigital color-primary">
                <span className="color-primary" style={{ fontWeight: "bold" }}>
                  supporto di un operatore certificato
                </span>{" "}
                in videochiamata
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginTop: "50px" }}
          >
            <Grid item xs={1}>
              <div className="icon-certified"></div>
            </Grid>
            <Grid item xs={11}>
              <Typography className="description-AdvantagesDigital color-primary">
                <span style={{ fontWeight: "bold" }}>
                  validità legale e riconosciuta in Europa
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4.5} style={{ height: "100%" }}>
        <Grid item xs={12} className="container-img-AdvantagesDigital">
          <div className="img-AdvantagesDigital"></div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdvantagesDigitalSignature;
