import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/AboutUs/SectionOne.css";

interface SectionWorkWithUsProps {}

const SectionWorkWithUs: React.FC<SectionWorkWithUsProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      className="container-TechSolution bg-orange-dark"
      style={{ display: "flex" }}
    >
      <Grid
        item
        xs={10}
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h2" className="color-light title-TechSolution">
          Ti riconosci nei nostri valori?
        </Typography>
        <Typography className="color-light description-TechSolution">
          Entra a far parte del nostro team: scopri le posizioni aperte o
          candidati spontaneamente. Non vediamo l’ora di conoscerti!
        </Typography>

        <div className="container-button-TechSolution">
          <a className="nav-link button-cta-TechSolution" href="">
            <Typography
              className="color-light text-20"
              style={{ fontWeight: 600 }}
            >
              Candidati
            </Typography>
          </a>
        </div>
      </Grid>
    </Grid>
  );
};

export default SectionWorkWithUs;
