import React, { useEffect, useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/Blog/BlogDetail.css";
import { KeyboardDoubleArrowLeftOutlined } from "@mui/icons-material";
import parse from "html-react-parser";
import CtaCompany from "../Company/CtaCompany";
import CtaDigitalSignature from "../DigitalSignature/CtaDigitalSignature";
import CtaShareTransfer from "../ShareTransfer/CtaShareTransfer";
import CtaTrademark from "../Trademark/CtaTrademark";
import Footer from "../Footer/Footer";
import axios, { AxiosResponse } from "axios";

interface BlogDetailProps {}

export interface Article {
  id?: string;
  title?: string;
  author?: string;
  summary?: string;
  published?: boolean;
  content?: string;
  createdAt?: number;
  updatedAt?: number;
  image_base64?: string;
  meta?: string;
  type?: string;
  imgPreview?: string;
}

const BlogDetail: React.FC<BlogDetailProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const [article, setArticle] = useState<Article | null>(null);

  useEffect(() => {
    document.body.setAttribute("data-root", window.location.pathname);

    const urlSlug = window.location.pathname.replace("/b/", " ");

    const fetchPublishedArticles = async () => {
      try {
        let baseUrl = "https://api.legalfarm.it";

        if (window.location.href.includes("https://www.legalfarm.it/")) {
          baseUrl = "https://api.legalfarm.it";
        }

        const trimmedUrlSlug = urlSlug.trim();

        const url = `${baseUrl}/blog/meta?slug=${trimmedUrlSlug}`;

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Errore nella richiesta: ${response.status}`);
        }

        const data = await response.json();
        const fetchedArticle = data.post;

        if (fetchedArticle.image_base64) {
          const imgPreview = await getImgCdn(fetchedArticle.image_base64);
          fetchedArticle.imgPreview = imgPreview;
        }

        setArticle(fetchedArticle);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPublishedArticles();

    return () => {
      document.body.removeAttribute("data-root");
    };
  }, []);

  const getImgCdn = async (id: string) => {
    try {
      let baseUrl = "https://api.legalfarm.it";

      if (window.location.href.includes("https://www.legalfarm.it/")) {
        baseUrl = "https://api.legalfarm.it";
      }

      const url = `${baseUrl}`;
      const response: AxiosResponse<any> = await axios.get(
        `${url}/blog_cdn/${id}`
      );

      if (response.status === 200) {
        return response.data.URL;
      } else {
        console.error(response.statusText);
        return "";
      }
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  return (
    <>
      {article ? (
        <>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "50px",
                marginBottom: "50px",
              }}
            >
              {article && (
                <>
                  <Typography className="color-primary title-article-blog">
                    {article.title}
                  </Typography>
                </>
              )}

              <div className="container-img-article-blog">
                {article && article.imgPreview && (
                  <img
                    className="img-article-blog"
                    src={article.imgPreview}
                    alt={article.meta || "Immagine articolo"}
                  />
                )}
              </div>

              <div
                style={{
                  paddingTop: "50px",
                  width: "80%",
                  margin: "0 auto",
                }}
              >
                {article && (
                  <>
                    <Typography className="color-primary content">
                      {parse(article.content!)}
                    </Typography>
                  </>
                )}
              </div>
            </Grid>

            <Grid xs={8} item style={{ marginBottom: "50px" }}>
              <a
                className="color-yellow"
                href={`/b`}
                style={{ cursor: "pointer" }}
              >
                <KeyboardDoubleArrowLeftOutlined
                  style={{ fontSize: "16px", marginLeft: "4px" }}
                />
                Vedi Altri Articoli
              </a>
            </Grid>

            {article && article.type === "Srl" ? (
              <CtaCompany ctaBlog={true} />
            ) : article && article.type === "Cessione" ? (
              <CtaShareTransfer ctaBlog={true} />
            ) : article && article.type === "Firma" ? (
              <CtaDigitalSignature ctaBlog={true} />
            ) : article && article.type === "Marchio" ? (
              <CtaTrademark ctaBlog={true} />
            ) : null}
          </Grid>
          <Footer />
        </>
      ) : null}
    </>
  );
};

export default BlogDetail;
