import React from "react";
import HeroTrademark from "../../components/Trademark/HeroTrademark";
import MetricsTrademark from "../../components/Trademark/MetricsTrademark";
import HowToRegisterTrademark from "../../components/Trademark/HowToRegisterTrademark";
import AdvantagesTrademark from "../../components/Trademark/AdvantagesTrademark";
import CardPriceTrademark from "../../components/Trademark/CardPriceTrademark";
import FaqTrademark from "../../components/Trademark/FaqTrademark";
import CtaTrademark from "../../components/Trademark/CtaTrademark";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

const Trademark: React.FC = () => {
  return (
    <>
      <Navbar />
      <HeroTrademark />
      <MetricsTrademark />
      <HowToRegisterTrademark />
      <AdvantagesTrademark />
      <CardPriceTrademark />
      <FaqTrademark />
      <CtaTrademark />
      <Footer />
    </>
  );
};

export default Trademark;
