import React from "react";
import ImgHeaderHome from "../../src/assets/img/header-home.jpg";
import SectionOne from "../components/AboutUs/SectionOne";
import SectionLexia from "../components/AboutUs/SectionLexia";
import SectionWant from "../components/AboutUs/SectionWant";
import SectionOurValue from "../components/AboutUs/SectionOurValues";
import TrustPilotAboutUs from "../components/AboutUs/TrustPilotAboutUs";
import SectionWorkWithUs from "../components/AboutUs/SectionWorkWithUs";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";

const AboutUsPage: React.FC = () => {
  return (
    <>
      <Navbar />
      <SectionOne />
      <SectionLexia />
      <SectionWant />
      <SectionOurValue />
      <TrustPilotAboutUs />
      <SectionWorkWithUs />
      <Footer />
    </>
  );
};

export default AboutUsPage;
