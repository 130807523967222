import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Logo from "../../assets/img/costituzioneSrl_logo.png";
import "../../assets/css/Navbar/navbar.css";
import "../../assets/css/Global.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faHome,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "../../utils/Translate/fileTranslate/i18n.js";
import { Grid, Typography } from "@mui/material";
import NavbarDesktop from "./NavbarDesktop";
import { useLocation } from "react-router-dom";

const lngs: {
  en: { icon: React.ReactNode };
  it: { icon: React.ReactNode };
} = {
  en: { icon: "it" },
  it: { icon: "en" },
};

const NavbarSmart: React.FC = () => {
  const location = useLocation();

  // codice traduzione
  const { t, i18n } = useTranslation();

  // gestione navbar smart & pc
  const [isOpen, setIsOpen] = useState(false);
  const [soluzioniVisible, setSoluzioniVisible] = useState(false);
  const [risorseVisible, setRisorseVisible] = useState(false);

  const toggleOffcanvas = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    document.body.style.overflow = isOpen ? "auto" : "hidden";
    // Aggiungi o rimuovi una classe per lo sfondo scuro
    document.body.classList.toggle("overlay-active", !isOpen);
  };

  const handleMenuClick = (id: number) => {
    if (id === 1) {
      setSoluzioniVisible(!soluzioniVisible);
      if (risorseVisible) {
        setRisorseVisible(false);
      }
    }

    if (id === 2) {
      setRisorseVisible(!risorseVisible);
      if (soluzioniVisible) {
        setSoluzioniVisible(false);
      }
    }
  };

  return (
    <>
      {/* smart  */}
      <nav className="navbar navbar-expand-lg navbar-smart mt-3">
        <Grid container>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Grid item xs={6} style={{ marginLeft: "10px" }}>
              <a
                href={"/"}
                style={{ textDecoration: "none", marginLeft: "20px" }}
                className="color-primary"
              >
                <div className="logo-mobile"></div>
              </a>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleOffcanvas}
              >
                <span className="navbar-toggler-icon">
                  <i className="bi bi-list icon-hamburger bg-primary"></i>
                  <i className="bi bi-list icon-hamburger bg-primary"></i>
                  <i className="bi bi-list icon-hamburger bg-primary"></i>
                </span>
              </button>
            </Grid>

            <div
              className={`offcanvas bg-light offcanvas-end ${
                isOpen ? "show" : ""
              }`}
              id="navbarNav"
            >
              <Grid xs={12} item className="h-100">
                {/* HOME */}
                <Grid
                  container
                  style={{ marginTop: "20px", paddingBottom: "15px" }}
                >
                  <Grid item xs={6}>
                    <div
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      <a href="/" className="icon-home-smart color-primary">
                        {/* <FontAwesomeIcon icon={faHouse} /> */}
                        <div
                          className={`${
                            location.pathname === "/"
                              ? "icon-home-smart-active"
                              : "icon-home-smart"
                          }`}
                        ></div>
                      </a>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "0px",
                      }}
                    >
                      <button
                        className="navbar-toggler"
                        type="button"
                        onClick={toggleOffcanvas}
                        aria-label="Close"
                      >
                        <span className="navbar-toggler-icon">
                          <div className="icon-close-smart"></div>
                          {/* <i className="fa-solid fa-xmark icon-close color-primary"></i> */}
                        </span>
                      </button>
                    </div>
                  </Grid>
                </Grid>

                {soluzioniVisible ? (
                  <Grid
                    container
                    className="soluzioni-dropdown-smart custom-animation bg-purple"
                    style={{
                      display: "flex",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    {/* <Grid
                      item
                      xs={9}
                      style={{
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <a
                        className="nav-link d-flex"
                        onClick={() => handleMenuClick(1)}
                      >
                        <Typography className="title-card-soluzioni color-light">
                          Soluzioni
                        </Typography>
                        <FontAwesomeIcon
                          className="color-light"
                          style={{
                            fontSize: "16px",
                            marginLeft: "5px",
                            marginTop: "5px",
                          }}
                          icon={faAngleDown}
                        />
                      </a>
                    </Grid> */}

                    <Grid
                      item
                      xs={12}
                      style={{
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Grid
                        item
                        xs={7}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography className="title-card-soluzioni color-light">
                          Soluzioni
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <a
                          className="nav-link d-flex"
                          style={{ marginRight: "22px" }}
                          onClick={() => handleMenuClick(1)}
                        >
                          <div className="icon-back-smart"></div>
                        </a>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={9}
                      component="a"
                      href="/apertura-srl-online"
                      className="card-soluzioni-smart bg-light link-soluzioni"
                      style={{
                        width: "100%",
                        marginBottom: "0px",
                        marginTop: "-80px",
                        textDecoration: "none",
                      }}
                    >
                      <Typography
                        className="title-card-soluzioni color-primary"
                        style={{ textAlign: "center" }}
                      >
                        Apertura Srl
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={9}
                      component="a"
                      href="/cessione-quote-srl-online"
                      className="card-soluzioni-smart bg-light link-soluzioni"
                      style={{
                        width: "100%",
                        marginBottom: "0px",
                        marginTop: "-80px",
                      }}
                    >
                      {" "}
                      <Typography
                        className="title-card-soluzioni color-primary"
                        style={{ textAlign: "center" }}
                      >
                        Cessione quote Srl
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      component="a"
                      href="/registrazione-marchio-online"
                      className="card-soluzioni-smart bg-light link-soluzioni"
                      xs={9}
                      style={{
                        width: "100%",
                        marginBottom: "0px",
                        marginTop: "-80px",
                      }}
                    >
                      {" "}
                      <Typography
                        className="title-card-soluzioni color-primary"
                        style={{ textAlign: "center" }}
                      >
                        Registrazione marchio
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={9}
                      component="a"
                      href="/firma-digitale"
                      className="card-soluzioni-smart bg-light link-soluzioni"
                      style={{
                        width: "100%",
                        marginBottom: "0px",
                        marginTop: "-80px",
                      }}
                    >
                      {" "}
                      <Typography
                        className="title-card-soluzioni color-primary"
                        style={{ textAlign: "center" }}
                      >
                        Attivazione firma digitale
                      </Typography>
                    </Grid>
                  </Grid>
                ) : risorseVisible ? (
                  <Grid
                    container
                    className="soluzioni-dropdown-smart custom-animation bg-purple"
                    style={{
                      display: "flex",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Grid
                        item
                        xs={7}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography className="title-card-soluzioni color-light">
                          Risorse
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <a
                          className="nav-link d-flex"
                          style={{ marginRight: "22px" }}
                          onClick={() => handleMenuClick(2)}
                        >
                          <div className="icon-back-smart"></div>
                        </a>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={9}
                      component="a"
                      href="/faq"
                      className="card-soluzioni-smart bg-light link-soluzioni"
                      style={{
                        width: "100%",
                        marginBottom: "0px",
                        marginTop: "-100px",
                      }}
                    >
                      {" "}
                      <Typography
                        className="title-card-soluzioni color-primary"
                        style={{ textAlign: "center" }}
                      >
                        Faq
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={9}
                      component="a"
                      href="/b"
                      className="card-soluzioni-smart bg-light link-soluzioni"
                      style={{
                        marginBottom: "0px",
                        marginTop: "-150px",
                      }}
                    >
                      {" "}
                      <a href="/b" className="link-soluzioni">
                        <Typography
                          className="title-card-soluzioni color-primary"
                          style={{ textAlign: "center" }}
                        >
                          Blog
                        </Typography>
                      </a>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{ marginLeft: "0px", marginTop: "100px" }}
                    >
                      <ul className="navbar-nav">
                        {/* SOLUZIONI  */}
                        <li className="nav-item container-smart margin-link mb-3">
                          <a
                            className="nav-link d-flex"
                            onClick={() => handleMenuClick(1)}
                          >
                            <Typography className="link-smart color-primary">
                              Soluzioni
                            </Typography>
                            <FontAwesomeIcon
                              className="icon-dropdown-smart color-primary"
                              icon={faAngleDown}
                            />
                          </a>
                        </li>

                        {/* PREZZI  */}
                        {/* <li className="nav-item container-smart margin-link mb-3 ">
                          <a className="nav-link" href="/price">
                            <Typography className="link-smart color-primary">
                              Prezzi
                            </Typography>
                          </a>
                        </li> */}

                        {/* RISORSE  */}
                        <li className="nav-item container-smart margin-link mb-3 ">
                          <a
                            className="nav-link d-flex"
                            onClick={() => handleMenuClick(2)}
                          >
                            <Typography className="link-smart color-primary">
                              Risorse
                            </Typography>
                            <FontAwesomeIcon
                              className="icon-dropdown-smart color-primary"
                              icon={faAngleDown}
                            />
                          </a>
                        </li>

                        {/* AZIENDA  */}
                        <li className="nav-item container-smart margin-link mb-3 ">
                          <a className="nav-link" href="/azienda">
                            <Typography className="link-smart color-primary">
                              Azienda
                            </Typography>
                          </a>
                        </li>

                        {/* ACCEDI */}
                        <Grid
                          container
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "30px",
                          }}
                        >
                          <Grid item xs={8}>
                            <a
                              style={{ width: "100%" }}
                              className="nav-link button-login bg-primary"
                              href="https://app.legalfarm.it/sign-up"
                            >
                              <Typography className="color-primary text-button">
                                Accedi
                              </Typography>
                            </a>
                          </Grid>
                        </Grid>
                      </ul>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </nav>
    </>
  );
};

export default NavbarSmart;
