import React from "react";
import BlogContainer from "../components/Blog/BlogContainer";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

const Blog: React.FC = () => {
  return (
    <>
      <Navbar />
      <BlogContainer />
      <Footer />
    </>
  );
};

export default Blog;
