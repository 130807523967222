import React, { useEffect, useState } from "react";
import { Theme, useTheme, styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { Link } from "react-router-dom";
import "../../assets/css/Blog/BlogContainer.css";
import CardArticleBlog from "../Generics/AccordionFaq/CardArticleBlog/CardArticleBlog";
import axios, { AxiosResponse } from "axios";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#001f3f",
    },
    "&:hover fieldset": {
      borderColor: "#001f3f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#001f3f",
    },
  },
}));

interface BlogContainerProps {}

const BlogContainer: React.FC<BlogContainerProps> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const [blogData, setBlogData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchPublishedArticles = async () => {
    try {
      setLoading(true);
      let baseUrl = "https://api.legalfarm.it";

      if (window.location.href.includes("https://www.legalfarm.it/")) {
        baseUrl = "https://api.legalfarm.it";
      }

      const url = `${baseUrl}/blog?published=true`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Errore nella richiesta: ${response.status}`);
      }
      const data = await response.json();
      const posts = data.posts.BlogPosts;


      const updatedPosts = await Promise.all(
        posts.map(async (post: any) => {
          const imageUrl = await getImgCdn(post.image_small);

          return { ...post, imgPreview: imageUrl };
        })
      );

      setBlogData(updatedPosts);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getImgCdn = async (id: string) => {
    try {
       let baseUrl = "https://api.legalfarm.it";

      if (window.location.href.includes("https://www.legalfarm.it/")) {
        baseUrl = "https://api.legalfarm.it";
      }

      const url = `${baseUrl}`;
      const response: AxiosResponse<any> = await axios.get(
        `${url}/blog_cdn/${id}`
      );

      if (response.status === 200) {
        return response.data.URL;
      } else {
        console.error(response.statusText);
        return "";
      }
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      let baseUrl = "https://api.legalfarm.it";

      if (window.location.href.includes("https://www.legalfarm.it/")) {
        baseUrl = "https://api.legalfarm.it";
      }

      const url = `${baseUrl}/blog/search?keyword=${searchValue}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Errore nella richiesta: ${response.status}`);
      }
      const data = await response.json();

      const posts = data.posts.BlogPosts || [];

      const updatedResults = await Promise.all(
        posts.map(async (post: any) => {
          const imageUrl = await getImgCdn(post.image_small);
          return { ...post, imgPreview: imageUrl };
        })
      );

      setSearchResults(updatedResults);
    } catch (error) {
      console.error(error);
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const clearSearchResults = () => {
    setSearchResults([]);
    setSearchValue("");
  };

  useEffect(() => {
    if (searchValue.trim() !== "") {
      handleSearch();
    } else {
      setSearchResults([]);
    }
  }, [searchValue]);

  useEffect(() => {
    fetchPublishedArticles();
  }, []);

  return (
    <>
      <Grid
        container
        className="container-Blog"
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <Grid
          item
          xs={8}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography component="h5" className="color-primary title-header-faq">
            Blog
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={10}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "60px",
          }}
        >
          <Grid item xs={7} style={{ position: "relative" }}>
            <StyledTextField
              id="searchInput"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              variant="outlined"
              placeholder="Cerca nel blog"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue && (
                      <IconButton
                        className="button-searchbar"
                        onClick={clearSearchResults}
                      >
                        <Close className="color-primary" />
                      </IconButton>
                    )}
                    <IconButton
                      className="button-searchbar"
                      onClick={handleSearch}
                    >
                      <Search className="color-primary" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* {searchResults && searchResults.length > 0 && (
              <List
                className="search-suggestions"
                style={{
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  right: 0,
                  marginTop: "10px",
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: 1000,
                  borderRadius: "5px",
                }}
              >
                {searchResults.map((result, index) => (
                  <ListItem
                    className="suggestions-link"
                    key={index}
                    component={Link}
                    to={`/b/${result.meta}`}
                  >
                    <ListItemText primary={result.title} />
                  </ListItem>
                ))}
              </List>
            )} */}
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        {loading ? (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-50px",
              marginBottom: "50px",
            }}
          >
            <CircularProgress className="color-primary" />
          </Grid>
        ) : (
          <Grid container className="bg-purple container-card-blog">
            {(searchResults.length > 0 ? searchResults : blogData).map(
              (article, index) => (
                <CardArticleBlog key={index} article={article} />
              )
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default BlogContainer;
