import React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import "../../assets/css/ShareTransfer/HowDoesThatWorkShareTransfer.css";

interface HowDoesThatWorkShareTransferProps {}

const HowDoesThatWorkShareTransfer: React.FC<
  HowDoesThatWorkShareTransferProps
> = ({}) => {
  const theme = useTheme<Theme>();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className="bg-purple container-card-services-shareTransfer">
      <Grid
        item
        xs={8}
        sm={10}
        md={10}
        lg={6}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          className="title-card-services-shareTransfer color-light"
        >
          Come funziona il processo
        </Typography>
      </Grid>

      <Grid
        md={10}
        xs={12}
        item
        container
        className="box-card-service-shareTransfer"
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={10} className="bubbles-container">
          <Grid
            item
            xs={12}
            className="container-interno-HowDoesThatShareTransfer"
          >
            <div
              className="bubble bubble-text first-bubble color-purple"
              style={{ width: "80px" }}
            >
              1
            </div>

            <div className="box-text">
              <p className="description-bubble p-0 mt-1 color-light">
                <span style={{ fontWeight: "bold" }}>
                  Compila un semplice modulo guidato
                </span>{" "}
                per creare l'atto di cessione in pochi minuti.
              </p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="container-interno-HowDoesThatShareTransfer"
          >
            <div
              className="bubble bubble-text second-bubble color-purple"
              style={{ width: "80px" }}
            >
              2
            </div>
            <div className="box-text">
              <p className="description-bubble p-0 m-0 mt-4 mt-sm-override color-light">
                <span style={{ fontWeight: "bold" }}>Carica i documenti</span>{" "}
                di società, venditori e acquirenti sul nostro portale.
              </p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="container-interno-HowDoesThatShareTransfer"
          >
            <div
              className="bubble bubble-text three-bubble color-purple"
              style={{ width: "80px" }}
            >
              3
            </div>
            <div className="box-text">
              <p className="description-bubble p-0 mt-4 mt-sm-override color-light">
                <span style={{ fontWeight: "bold" }}>
                  Verifichiamo l'identità
                </span>{" "}
                delle parti in 5 minuti di videochiamata.
              </p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            className="container-interno-HowDoesThatShareTransfer"
          >
            <div
              className="bubble bubble-text three-bubble color-purple"
              style={{ width: "80px" }}
            >
              <div className="icon-check-stepFour" />
            </div>
            <div className="box-text">
              <p className="description-bubble p-0 mt-1 mt-sm-override color-light">
                <span style={{ fontWeight: "bold" }}>Inviamo la pratica</span>{" "}
                agli enti competenti e ti restituiamo la visura aggiornata della
                società.
              </p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HowDoesThatWorkShareTransfer;
